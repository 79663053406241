import React from "react";
import {H1, H2, TextL} from "@mryaros/mryaros-design-system";

export const PageMain = React.memo(function PageMain() {
    return (
        <>
            <H1>Исследование задач управления</H1>
            <H2>Типы задач для исследования</H2>
            <ul >
                <li><TextL className="margin-b-8">Задача управления для линейной дифференциальной модели с терминальным целевым функционалом</TextL></li>
                <li><TextL className="margin-b-8">Задача управления для линейной дифференциальной модели с заданным целевым вектор-функционалом</TextL></li>
                <li><TextL className="margin-b-8">Задача оптимального управления для линейной дифференциальной модели</TextL></li>
                <li><TextL className="margin-b-8">Задача оптимального управления в классе кусочно-постоянных управлений</TextL></li>
            </ul>

            <H2>Сферы применения</H2>
            <TextL className="margin-b-8">
                Разработанное web-приложение может применяться во многих отраслях. В первую очередь стоит упомянуть о таких отраслях, в которых результатом деятельности может выступать производственное накопление.
                К таким отраслям относятся добывающая промышленность, электроэнергетика, металлургия, машиностроение, электронная промышленность, химическая, легкая и пищевая промышленность, сельское хозяйство, лесное хозяйство.
            </TextL>
            <TextL className="margin-b-8">Активными пользователями созданного web-приложения могут стать владельцы малого, большого и среднего бизнеса.
                   С помощью web-приложения у собственников компаний появится возможность более осознанно инвестировать в деятельность своей компании, основываясь не только на собственном опыте и интуиции, но и на теории оптимального управления не погружаясь в ее основы.
                   Классическим примером, когда появится необходимость воспользоваться web-приложением, может послужить ситуация, в которой компания активно занимается и развивает один род деятельности и хочет заняться вторым или смежным родом деятельности, не потеряв позиций по основному направлению.
                   В этом случае достаточно ввести необходимые параметры для решения задачи оптимального управления и воспользоваться результатом решения.
            </TextL>
            <TextL>
                Также разработанное web-приложение может применяться в таких областях, как геология, логистика, товароведение, менеджмент, строительство, торговля, общественное питание, здравоохранение и пр.
            </TextL>

            <H2>Что еще?</H2>
            <TextL className="margin-b-8">
                Помимо явной практической пользы разработанное web-приложение несет и научную, образовательную полезность. С его помощью появляется возможность изучать теорию оптимального управления и тут же применять её на практике без дополнительных знаний сторонних программ, языков программирования, библиотек для выполнения вычислений и построения графиков.
            </TextL>

            <H2>Скоро в приложении</H2>
            <ul >
                <li><TextL className="margin-b-8">Избавится от заданной размерности системы и добавить возможность пользователю самому её вводить</TextL></li>
                <li><TextL className="margin-b-8">Добавить возможность решения рассмотренных задач для дискретного времени</TextL></li>
                <li><TextL className="margin-b-8">Построение моделей на основе файла с данными или путем интеграции с сторонними системами</TextL></li>
            </ul>
        </>
    );
});