import React, {useEffect, useState} from "react";
import {LineNumberChartData, H2, H4, LineChart, Section, TabControl, TextField, TextS} from "@mryaros/mryaros-design-system";
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";
import {OptimalManagementTaskTab} from "./optimal/OptimalManagementTaskTab";

enum TabId {
    MANAGEMENT_TASK_WITH_TERMINAL = "MANAGEMENT_TASK_WITH_TERMINAL",
    MANAGEMENT_TASK_WITH_GIVEN = "MANAGEMENT_TASK_WITH_GIVEN",
    OPTIMAL_MANAGEMENT_TASK = "OPTIMAL_MANAGEMENT_TASK",
    OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL = "OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL",
}

export const PageResolveTask = React.memo(function PageResolveTask() {
    const [activeTab, setActiveTab] = useState(TabId.MANAGEMENT_TASK_WITH_TERMINAL);

    return (
        <>
            <H2>Решение задачи управления</H2>
            <TabControl  selectedTabId={activeTab} onTabChange={(tab) => setActiveTab(tab as TabId)}>
                <ManagementTaskWithTerminalTab tabId={TabId.MANAGEMENT_TASK_WITH_TERMINAL} tabName={<>Задача управления <br/> для линейной дифференциальной модели <br/> с терминальным целевым функционалом</>} />
                <ManagementTaskWithGivenTab tabId={TabId.MANAGEMENT_TASK_WITH_GIVEN} tabName={<>Задача управления <br/> для линейной дифференциальной модели <br/> с заданным целевым вектор-функционалом</>} />
                <OptimalManagementTaskTab tabId={TabId.OPTIMAL_MANAGEMENT_TASK} tabName={<>Задача оптимального управления <br/> для линейной дифференциальной модели</>} />
                <OptimalManagementTaskWithPiecewiseConstantControlTab tabId={TabId.OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL} tabName={<>Задача оптимального управления <br/> в классе кусочно-постоянных управлений</>} />
            </TabControl>
        </>
    );
})

// eslint-disable-next-line no-empty-pattern
function ManagementTaskWithTerminalTab({}: TabHeaderComponentProps) {
    const [update, setUpdate] = useState<boolean>();
    const [k, setK] = useState<string>();
    const [b, setB] = useState<string>();
    const [data, setData] = useState<Array<LineNumberChartData>>();

    useEffect(() => {
        setUpdate(false);
        if (!k || !b) {
            return;
        }

        const _data = new Array(10);
        for (let i = 0; i < 10; i++) {
            let y = +k * i + +b;
            _data[i] = [i, y];
        }
        setData(_data);
    }, [k, b]);

    useEffect(() => {
        setUpdate(true);
    }, [data])

    return (
        <>
            <Section className="padding-text margin-t-24">
                <H4 className="margin-no">Введите параметры линейной функции</H4>
                <TextS>Линейная функция имеет вид y=K*x+B</TextS>

                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">K = </TextS>
                    <TextField onChange={(k: string) => setK(k)} />
                </div>
                <div className="flex-row flex-align-center">
                    <TextS className="margin-b-8 margin-r-8">B = </TextS>
                    <TextField onChange={(b: string) => setB(b)} />
                </div>
            </Section>
            {update && data && (<Section className="padding-medium margin-t-24">
                <LineChart header = "Линейная функция" description={`y=${k}x+${b}`} data={data} />
            </Section>)}
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function ManagementTaskWithGivenTab({}: TabHeaderComponentProps) {
    const [update, setUpdate] = useState<boolean>();
    const [a, setA] = useState<string>();
    const [b, setB] = useState<string>();
    const [c, setC] = useState<string>();
    const [data, setData] = useState<Array<LineNumberChartData>>();

    useEffect(() => {
        setUpdate(false);
        if (!a || !b || !c) {
            return;
        }

        const _data = new Array(10);
        for (let i = 0; i < 10; i++) {
            let y = +a * i * i + +b * i + +c;
            _data[i] = [i, y];
        }
        setData(_data);
    }, [a, b, c]);

    useEffect(() => {
        setUpdate(true);
    }, [data])

    return (
        <>
            <Section className="padding-text margin-t-24">
                <H4 className="margin-no">Введите параметры квадратичной функции</H4>
                <TextS>Квадратичная функция имеет вид y=A*x*x+B*x+C</TextS>

                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">A = </TextS>
                    <TextField onChange={(a: string) => setA(a)} />
                </div>
                <div className="flex-row flex-align-center margin-b-16">
                    <TextS className="margin-b-8 margin-r-8">B = </TextS>
                    <TextField onChange={(b: string) => setB(b)} />
                </div>
                <div className="flex-row flex-align-center">
                    <TextS className="margin-b-8 margin-r-8">C = </TextS>
                    <TextField onChange={(c: string) => setC(c)} />
                </div>
            </Section>
            {update && data && (<Section className="padding-medium margin-t-24">
                <LineChart header = "Квадратичная функция" description={`y=${a}*x*x+${b}*x+${c}`} data={data} curve />
            </Section>)}
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function OptimalManagementTaskWithPiecewiseConstantControlTab({}: TabHeaderComponentProps) {
    return (
        <>
        </>
    );
}