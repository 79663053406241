import {TextField} from "@mryaros/mryaros-design-system";

type Props = {
    values: Array<Array<string>>;
    labels: Array<Array<string>>;
    onChange: (value: string, index1: number, index2: number) => void;
};

export function MatrixInput({values, labels, onChange}: Props) {
    return (
        <div className="margin-all-b-8">
            {values.map((mas1, index1) => {
                return (
                    <div className="flex-row margin-all-r-8 text-block" key={index1}>
                        {mas1.map((mas2, index2) => {
                            return (
                                <TextField
                                    key={index1 + " " + index2}
                                    label={labels[index1][index2] || ""}
                                    value={mas2}
                                    onChange={(val) => onChange(val, index1, index2)}
                                />
                            )
                        })}
                    </div>
                )
            })}
        </div>
    );
}