import React, {useState} from "react";
import {H2, Image, TabControl} from "@mryaros/mryaros-design-system";
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";
import terminal_1 from "../../../static/theory/terminal_1.png";
import terminal_2 from "../../../static/theory/terminal_2.png";
import given_1 from "../../../static/theory/given_1.png";
import given_2 from "../../../static/theory/given_2.png";
import optimal_1 from "../../../static/theory/optimal_1.png";
import optimal_2 from "../../../static/theory/optimal_2.png";
import piecewise_1 from "../../../static/theory/piecewise_1.png";

enum TabId {
    MANAGEMENT_TASK_WITH_TERMINAL = "MANAGEMENT_TASK_WITH_TERMINAL",
    MANAGEMENT_TASK_WITH_GIVEN = "MANAGEMENT_TASK_WITH_GIVEN",
    OPTIMAL_MANAGEMENT_TASK = "OPTIMAL_MANAGEMENT_TASK",
    OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL = "OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL",
}

export const PageTheory = React.memo(function PageTheory() {
    const [activeTab, setActiveTab] = useState(TabId.MANAGEMENT_TASK_WITH_TERMINAL);

    return (
        <>
            <H2>Сводка теории по типам рассматриваемых задач</H2>
            <TabControl  selectedTabId={activeTab} onTabChange={(tab) => setActiveTab(tab as TabId)}>
                <ManagementTaskWithTerminalTab tabId={TabId.MANAGEMENT_TASK_WITH_TERMINAL} tabName={<>Задача управления <br/> для линейной дифференциальной модели <br/> с терминальным целевым функционалом</>} />
                <ManagementTaskWithGivenTab tabId={TabId.MANAGEMENT_TASK_WITH_GIVEN} tabName={<>Задача управления <br/> для линейной дифференциальной модели <br/> с заданным целевым вектор-функционалом</>} />
                <OptimalManagementTaskTab tabId={TabId.OPTIMAL_MANAGEMENT_TASK} tabName={<>Задача оптимального управления <br/> для линейной дифференциальной модели</>} />
                <OptimalManagementTaskWithPiecewiseConstantControlTab tabId={TabId.OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL} tabName={<>Задача оптимального управления <br/> в классе кусочно-постоянных управлений</>} />
            </TabControl>
        </>
    );
})

// eslint-disable-next-line no-empty-pattern
function ManagementTaskWithTerminalTab({}: TabHeaderComponentProps) {
    return (
        <>
            <Image className="margin-t-24" src={terminal_1} alt="ManagementTaskWithTerminal1" />
            <Image src={terminal_2} alt="ManagementTaskWithTerminal2" />
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function ManagementTaskWithGivenTab({}: TabHeaderComponentProps) {
    return (
        <>
            <Image className="margin-t-24" src={given_1} alt="ManagementTaskWithGiven1" />
            <Image src={given_2} alt="ManagementTaskWithGiven2" />
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function OptimalManagementTaskTab({}: TabHeaderComponentProps) {
    return (
        <>
            <Image className="margin-t-24" src={optimal_1} alt="OptimalManagementTask1" />
            <Image src={optimal_2} alt="OptimalManagementTask2" />
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function OptimalManagementTaskWithPiecewiseConstantControlTab({}: TabHeaderComponentProps) {
    return (
        <>
            <Image className="margin-t-24" src={piecewise_1} alt="OptimalManagementTaskWithPiecewiseConstantControl1" />
        </>
    );
}