// eslint-disable-next-line no-empty-pattern
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";
import {useCallback, useContext, useEffect, useState} from "react";
import {LineChart, LineNumberChartData, Loader, MasterContext, MasterProvider, MasterStep, NumberField, TextL} from "@mryaros/mryaros-design-system";
import {MasterAccumulatorInterface} from "@mryaros/mryaros-design-system/src/lib/core/Master/context/@types/MasterContext";
import "../text-field.scss";
import {MatrixInput} from "../components/MatrixInput";
import {SessionContext} from "../../../core/context/SessionContext";
import {RequestEndpoint} from "../../../core/RequestEndpoint";
import {ERequestMethod} from "../../../core/RequestExecutor";
import {OptimalManagementTaskResponse} from "../../../core/response/ldm/OptimalManagementTaskResponse";

type AccumulatorType = {
    /** Основные параметры задачи */
    timeInterval: number | undefined;
    matrixF: Array<Array<string>>;
    matrixG: Array<Array<string>>;
    fMatrix: Array<Array<string>>;
    M: number | undefined;
    aMatrix: Array<Array<string>>;
    Nbig: number | undefined;
    bMatrix: Array<Array<string>>;
    K: number | undefined;

    /** Ограничения */
    x0Matrix: Array<Array<string>>;
    Bbig: Array<Array<string>>;
    qMatrix: Array<Array<string>>;
} & MasterAccumulatorInterface;

// eslint-disable-next-line no-empty-pattern
export function OptimalManagementTaskTab({}: TabHeaderComponentProps) {

    return (
        <div className="margin-t-24">
            <MasterProvider<AccumulatorType>>
                <MasterStep caption="Основные параметры задачи" body={<OptimalStep1 />} />
                <MasterStep caption="Ограничения на управление" body={<OptimalStep2 />} />
                <MasterStep caption="Результат решения задачи" body={<OptimalStep3 />} isResultStep={true} />
            </MasterProvider>
        </div>
    );
}

function OptimalStep1() {
    return (
        <>
            <TimeInterval />
            <MatrixF />
            <MatrixG />
            <FMatrix />
            <TextL className="margin-t-24 margin-b-8">Весовой коэффициент для фазовой составляющей функционала (для потенциальной возможности варьирования)</TextL>
            <MBig />
            <AMatrix />
            <TextL className="margin-t-24 margin-b-8">Весовой коэффициент для управляющей составляющей (для потенциальной возможности варьирования)</TextL>
            <NBig />
            <BMatrix />
            <K />
        </>
    );
}

function TimeInterval() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [timeInterval, setTimeInterval] = useState<number | undefined>(_accumulator.timeInterval);

    useEffect(() => {
        _accumulator.timeInterval = timeInterval;
    }, [_accumulator, timeInterval]);
    
    return (
        <div className="flex-row flex-align-center text-block">
            <TextL className="margin-no">Промежуток времени: [0,</TextL>
            <NumberField
                label="T"
                value={timeInterval as unknown as string || ""}
                onChange={(value) => setTimeInterval(value as unknown as number || undefined)}
            />
            ]
        </div>
    );
}

function MatrixF() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [matrixF, setMatrixF] = useState<Array<Array<string>>>(_accumulator.matrixF || [["", ""], ["", ""]]);

    useEffect(() => {
        _accumulator.matrixF = matrixF;
    }, [_accumulator, matrixF]);

    
    const setMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _matrixF = Array.from(matrixF);
        _matrixF[index1][index2] = value;

        setMatrixF(_matrixF);
    }, [matrixF]);
    
    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">Матрица коэффициентов системы при x:</TextL>
            <MatrixInput
                onChange={setMatrixValue}
                values={matrixF}
                labels={[["f11", "f12"], ["f21", "f22"]]}
            />
        </div>
    );
}

function MatrixG() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [matrixG, setMatrixG] = useState<Array<Array<string>>>(_accumulator.matrixG || [["", ""], ["", ""]]);

    useEffect(() => {
        _accumulator.matrixG = matrixG;
    }, [_accumulator, matrixG]);

    const setMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _matrixG = Array.from(matrixG);
        _matrixG[index1][index2] = value;

        setMatrixG(_matrixG);
    }, [matrixG]);

    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">Матрица коэффициентов при управлении:</TextL>
            <MatrixInput
                onChange={setMatrixValue}
                values={matrixG}
                labels={[["g11", "g12"], ["g21", "g22"]]}
            />
        </div>
    );
}

function FMatrix() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [fMatrix, setFMatrix] = useState<Array<Array<string>>>(_accumulator.fMatrix || [[""], [""]]);

    useEffect(() => {
        _accumulator.fMatrix = fMatrix;
    }, [_accumulator, fMatrix]);

    const setMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _fMatrix = Array.from(fMatrix);
        _fMatrix[index1][index2] = value;

        setFMatrix(_fMatrix);
    }, [fMatrix]);

    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">Внешнее воздействие на систему:</TextL>
            <MatrixInput
                onChange={setMatrixValue}
                values={fMatrix}
                labels={[["f1"], ["f2"]]}
            />
        </div>
    );
}

function MBig() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [M, setM] = useState<number | undefined>(_accumulator.M);

    useEffect(() => {
        _accumulator.M = M;
    }, [_accumulator, M]);

    return (
        <div className="flex-row flex-align-center text-block">
            <TextL className="margin-b-8 margin-r-8">M:</TextL>
            <NumberField
                label="M"
                value={M as unknown as string || ""}
                onChange={(value) => setM(value as unknown as number || undefined)}
            />
        </div>
    );
}

function AMatrix() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [aMatrix, setAMatrix] = useState<Array<Array<string>>>(_accumulator.aMatrix || [[""], [""]]);

    useEffect(() => {
        _accumulator.aMatrix = aMatrix;
    }, [_accumulator, aMatrix]);

    const setMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _aMatrix = Array.from(aMatrix);
        _aMatrix[index1][index2] = value;

        setAMatrix(_aMatrix);
    }, [aMatrix]);

    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">a:</TextL>
            <div className="flex-row flex-align-center">
                <TextL className="margin-b-8 margin-r-8">M *</TextL>
                <MatrixInput
                    onChange={setMatrixValue}
                    values={aMatrix}
                    labels={[["a1"], ["a2"]]}
                />
            </div>
        </div>
    );
}

function NBig() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [N, setN] = useState<number | undefined>(_accumulator.Nbig);

    useEffect(() => {
        _accumulator.Nbig = N;
    }, [_accumulator, N]);

    return (
        <div className="flex-row flex-align-center text-block">
            <TextL className="margin-b-8 margin-r-8">N:</TextL>
            <NumberField
                label="N"
                value={N as unknown as string || ""}
                onChange={(value) => setN(value as unknown as number || undefined)}
            />
        </div>
    );
}

function BMatrix() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [bMatrix, setBMatrix] = useState<Array<Array<string>>>(_accumulator.bMatrix || [[""], [""]]);

    useEffect(() => {
        _accumulator.bMatrix = bMatrix;
    }, [_accumulator, bMatrix]);

    const setMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _bMatrix = Array.from(bMatrix);
        _bMatrix[index1][index2] = value;

        setBMatrix(_bMatrix);
    }, [bMatrix]);

    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">b:</TextL>
            <div className="flex-row flex-align-center">
                <TextL className="margin-b-8 margin-r-8">N *</TextL>
                <MatrixInput
                    onChange={setMatrixValue}
                    values={bMatrix}
                    labels={[["b1"], ["b2"]]}
                />
            </div>
        </div>
    );
}

function K() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [K, setK] = useState<number | undefined>(_accumulator.K);

    useEffect(() => {
        _accumulator.K = K;
    }, [_accumulator, K]);

    return (
        <div className="flex-row flex-align-center margin-t-24 text-block">
            <TextL className="margin-b-8 margin-r-8">Количество временных промежутков:</TextL>
            <NumberField
                label="K"
                value={K as unknown as string || ""}
                onChange={(value) => setK(value as unknown as number || undefined)}
            />
        </div>
    );
}

function OptimalStep2() {
    return (
        <>
            <X0Matrix />
            <BBig />
        </>
    );
}

function X0Matrix() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [x0Matrix, setX0Matrix] = useState<Array<Array<string>>>(_accumulator.x0Matrix || [[""], [""]]);

    useEffect(() => {
        _accumulator.x0Matrix = x0Matrix;
    }, [_accumulator, x0Matrix]);

    const setMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _x0Matrix = Array.from(x0Matrix);
        _x0Matrix[index1][index2] = value;

        setX0Matrix(_x0Matrix);
    }, [x0Matrix]);

    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">Начальное состояние:</TextL>
            <MatrixInput
                onChange={setMatrixValue}
                values={x0Matrix}
                labels={[["x01"], ["x02"]]}
            />
        </div>
    );
}

function BBig() {
    const {accumulator} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;
    const [Bbig, setBbig] = useState<Array<Array<string>>>(_accumulator.Bbig || [["", ""], ["", ""], ["", ""], ["", ""], ["", ""]]);
    const [qMatrix, setQMatrix] = useState<Array<Array<string>>>(_accumulator.qMatrix || [[""], [""], [""], [""], [""]]);

    useEffect(() => {
        _accumulator.Bbig = Bbig;
    }, [_accumulator, Bbig]);

    const setBbigValue = useCallback((value: string, index1: number, index2: number) => {
        const _Bbig = Array.from(Bbig);
        _Bbig[index1][index2] = value;

        setBbig(_Bbig);
    }, [Bbig]);

    useEffect(() => {
        _accumulator.qMatrix = qMatrix;
    }, [_accumulator, qMatrix]);

    const setQMatrixValue = useCallback((value: string, index1: number, index2: number) => {
        const _qMatrix = Array.from(qMatrix);
        _qMatrix[index1][index2] = value;

        setQMatrix(_qMatrix);
    }, [qMatrix]);

    return (
        <div>
            <TextL className="margin-t-24 margin-b-8">Матрица ограничений на управление:</TextL>
            <div className="flex-row flex-align-center margin-all-r-24">
                <MatrixInput
                    onChange={setBbigValue}
                    values={Bbig}
                    labels={[["B11", "B12"], ["B21", "B22"], ["B31", "B32"], ["B41", "B42"], ["B51", "B52"]]}
                />
                <TextL className="margin-b-8">&#xD7;</TextL>
                <TextL className="margin-b-8">u(t)</TextL>
                <TextL className="margin-b-8">&le;</TextL>
                <MatrixInput
                    onChange={setQMatrixValue}
                    values={qMatrix}
                    labels={[["q1"], ["q2"], ["q3"], ["q4"], ["q5"]]}
                />
            </div>
        </div>
    );
}

function OptimalStep3() {
    const {accumulator, handleMasterError} = useContext(MasterContext);
    const _accumulator = accumulator as AccumulatorType;

    const {callWithTokenCheck} = useContext(SessionContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [response, setResponse] = useState<OptimalManagementTaskResponse>();

    const [u1, setU1] = useState<Array<LineNumberChartData>>([]);
    const [u2, setU2] = useState<Array<LineNumberChartData>>([]);

    const [x1, setX1] = useState<Array<LineNumberChartData>>([]);
    const [x2, setX2] = useState<Array<LineNumberChartData>>([]);

    const solveTask = useCallback(() => {
        const request: OptimalTaskRequest = {
            ldmTaskTypeId: 3,
            T: _accumulator.timeInterval || 0,
            F: _accumulator.matrixF.map((m1) => m1.map((m2) => parseFloat(m2))),
            G: _accumulator.matrixG.map((g1) => g1.map((g2) => parseFloat(g2))),
            n: 2,
            ft: [_accumulator.fMatrix[0][0], _accumulator.fMatrix[1][0]],
            x0: [parseFloat(_accumulator.x0Matrix[0][0]), parseFloat(_accumulator.x0Matrix[1][0])],
            M: _accumulator.M || 0,
            a: ["M * " + _accumulator.aMatrix[0][0], "M * " + _accumulator.aMatrix[1][0]],
            Nbig: _accumulator.Nbig || 0,
            Bbig: _accumulator.Bbig.map((m1) => m1.map((m2) => parseFloat(m2))),
            b: ["N * " + _accumulator.bMatrix[0][0], "N * " + _accumulator.bMatrix[1][0]],
            q: [parseFloat(_accumulator.qMatrix[0][0]), parseFloat(_accumulator.qMatrix[1][0]), parseFloat(_accumulator.qMatrix[2][0]), parseFloat(_accumulator.qMatrix[3][0]), parseFloat(_accumulator.qMatrix[4][0])],
            K: _accumulator.K || 0,
        };

        callWithTokenCheck(RequestEndpoint.solveOptimalManagementTask, ERequestMethod.POST, request)
            .then(r => setResponse(r))
            .catch(_ => {
                handleMasterError( {
                    errorTitle: "Произошла ошибка",
                    errorMessage: "Во время решения задачи произошла ошибка. Проверьте корректность введенных данных или обратитесь к администратору"
                } )
            })
            .finally(() => setLoading(false));
    }, [_accumulator, callWithTokenCheck, handleMasterError]);

    useEffect(() => {
        solveTask();
    }, [solveTask]);

    useEffect(() => {
        let dataSet1: Array<LineNumberChartData> = [];
        let dataSet2: Array<LineNumberChartData> = [];
        const step = (_accumulator.timeInterval || 0) / (_accumulator.K || 1);

        if (!response) {
            return;
        }
        (response as OptimalManagementTaskResponse).optU.forEach((u, index) => {
            dataSet1.push([step * index, u.x]);
            dataSet1.push([step * (index + 1), u.x]);
            dataSet2.push([step * index, u.y]);
            dataSet2.push([step * (index + 1), u.y]);
        });

        dataSet1 = dataSet1.filter((data, index) => (dataSet1.length - 1 === index) || (!!dataSet1[index + 1] && !(data[0] === dataSet1[index + 1][0] && data[1] === dataSet1[index + 1][1])));
        dataSet2 = dataSet2.filter((data, index) => (dataSet2.length - 1 === index) || (!!dataSet2[index + 1] && !(data[0] === dataSet2[index + 1][0] && data[1] === dataSet2[index + 1][1])));

        if (dataSet1.length) {
            setU1(dataSet1);
        }

        if (dataSet2.length) {
            setU2(dataSet2);
        }
    }, [_accumulator.K, _accumulator.timeInterval, response]);

    useEffect(() => {
        let dataSet1: Array<LineNumberChartData> = [];
        let dataSet2: Array<LineNumberChartData> = [];

        const lines = (response as OptimalManagementTaskResponse)?.lines
        if (lines && lines[0]) {
            lines!![0].x.forEach((x, index) => {
                dataSet1.push([x, lines!![0].y[index]])
            });
        }

        if (lines && lines[1]) {
            lines!![1].x.forEach((x, index) => {
                dataSet2.push([x, lines!![1].y[index]])
            });
        }


        if (dataSet1.length) {
            setX1(dataSet1);
        }

        if (dataSet2.length) {
            setX2(dataSet2);
        }
    }, [response]);

    return (
        <>
            {loading ? <Loader header="Идет процесс решения задачи" description="Пожалуйста, подождите, Ваша задача решается..." /> : null}
            <div className="flex-row flex-j-around">
                {u1.length ? <LineChart header="1-я компонента управления" data={u1} /> : null}
                {u2.length ? <LineChart header="2-я компонента управления" data={u2} /> : null}
            </div>
            <div className="flex-row flex-j-around margin-t-48">
                {x1.length ? <LineChart header="Порождаемая траектория" description="1-я компонента" data={x1} useDot={false} /> : null}
                {x2.length ? <LineChart header="Порождаемая траектория" description="2-я компонента" data={x2} useDot={false} /> : null}
            </div>
        </>
    );
}

type OptimalTaskRequest = {
    ldmTaskTypeId: number,
    F: Array<Array<number>>,
    G: Array<Array<number>>,
    T: number,
    n: number,
    ft: Array<string>,
    x0: Array<number>,
    M: number,
    a: Array<string>,
    Nbig: number,
    Bbig: Array<Array<number>>,
    b: Array<string>,
    q: Array<number>,
    K: number,
}