import React, {useState} from "react";
import {H2, H4, Image, TabControl, TextL} from "@mryaros/mryaros-design-system";
import {TabHeaderComponentProps} from "@mryaros/mryaros-design-system/dist/lib/components/controls/Tab/TabHeaderItem";
import {Link} from "react-router-dom";
import LinearParamExample from "../../../static/LinearParamExample.png";
import LinearResultExample from "../../../static/LinearResultExample.png";
import SquareParamExample from "../../../static/SquareParamExample.png";
import SquareResultExample from "../../../static/SquareResultExample.png";
import CubicParamExample from "../../../static/CubicParamExample.png";
import CubicResultExample from "../../../static/CubicResultExample.png";
import optimal_1 from "../../../static/example/optimal/example_optimal_1.png";
import optimal_2 from "../../../static/example/optimal/example_optimal_2.png";
import optimal_3 from "../../../static/example/optimal/example_optimal_3.png";
import optimal_4 from "../../../static/example/optimal/example_optimal_4.png";
import optimal_5 from "../../../static/example/optimal/example_optimal_5.png";
import optimal_6 from "../../../static/example/optimal/example_optimal_6.png";
import optimal_7 from "../../../static/example/optimal/example_optimal_7.png";

enum TabId {
    MANAGEMENT_TASK_WITH_TERMINAL = "MANAGEMENT_TASK_WITH_TERMINAL",
    MANAGEMENT_TASK_WITH_GIVEN = "MANAGEMENT_TASK_WITH_GIVEN",
    OPTIMAL_MANAGEMENT_TASK = "OPTIMAL_MANAGEMENT_TASK",
    OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL = "OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL",
}

export const PageExample = React.memo(function PageExample() {
    const [activeTab, setActiveTab] = useState(TabId.MANAGEMENT_TASK_WITH_TERMINAL);

    return (
        <>
            <H2>Примеры решения задач</H2>
            <TabControl  selectedTabId={activeTab} onTabChange={(tab) => setActiveTab(tab as TabId)}>
                <ManagementTaskWithTerminalTab tabId={TabId.MANAGEMENT_TASK_WITH_TERMINAL} tabName={<>Задача управления <br/> для линейной дифференциальной модели <br/> с терминальным целевым функционалом</>} />
                <ManagementTaskWithGivenTab tabId={TabId.MANAGEMENT_TASK_WITH_GIVEN} tabName={<>Задача управления <br/> для линейной дифференциальной модели <br/> с заданным целевым вектор-функционалом</>} />
                <OptimalManagementTaskTab tabId={TabId.OPTIMAL_MANAGEMENT_TASK} tabName={<>Задача оптимального управления <br/> для линейной дифференциальной модели</>} />
                <OptimalManagementTaskWithPiecewiseConstantControlTab tabId={TabId.OPTIMAL_MANAGEMENT_TASK_WITH_PIECEWISE_CONSTANT_CONTROL} tabName={<>Задача оптимального управления <br/> в классе кусочно-постоянных управлений</>} />
            </TabControl>
        </>
    );
})

// eslint-disable-next-line no-empty-pattern
function ManagementTaskWithTerminalTab({}: TabHeaderComponentProps) {
    return (
        <>
            <TextL className="margin-t-24">
                Перед решением задачи предлагается ознакомится с выжимками <Link to="/theory">теории</Link> для данного типа задачи.
                Для построения графика линейной функции необходимо заполнить необходимые параметры.
            </TextL>
            <Image src={LinearParamExample} alt="LinearParamExample" name="Рисунок 1. Пример введения параметров" />
            <TextL>
                После чего ниже появится график функции, который соответствует введенным параметрам.
            </TextL>
            <Image src={LinearResultExample} alt="LinearResultExample" name="Рисунок 2. Результат построения графика с введенными параметрами" />
            <TextL>
                Задачу построения графика линейной функции можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function ManagementTaskWithGivenTab({}: TabHeaderComponentProps) {
    return (
        <>
            <TextL className="margin-t-24">
                Перед решением задачи предлагается ознакомится с выжимками <Link to="/theory">теории</Link> для данного типа задачи.
                Для построения графика квадратичной функции необходимо заполнить необходимые параметры.
            </TextL>
            <Image src={SquareParamExample} alt="SquareParamExample" name="Рисунок 1. Пример введения параметров" />
            <TextL>
                После чего ниже появится график функции, который соответствует введенным параметрам.
            </TextL>
            <Image src={SquareResultExample} alt="SquareResultExample" name="Рисунок 2. Результат построения графика с введенными параметрами" />
            <TextL>
                Задачу построения графика квадратичной функции можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function OptimalManagementTaskTab({}: TabHeaderComponentProps) {
    return (
        <>
            <H4>Рассматривается задача оптимального управления</H4>
            <Image src={optimal_2} alt="optimal_2" name="Рисунок 1. Параметры задачи" />
            <TextL>
                Сначала необходимо задать параметры целевого минимизируемого функционала
            </TextL>
            <Image src={optimal_3} alt="optimal_3" name="Рисунок 2. Матрица коэффициентов системы" />
            <Image src={optimal_4} alt="optimal_4" name="Рисунок 3. Матрица коэффициентов при управлнеии" />
            <Image src={optimal_5} alt="optimal_5" name="Рисунок 4. Весовые коэффициенты" />
            <TextL>
                После ввода параметров целевого минимизируемого функционала необходимо задать начальное состояние и ограничения на управление
            </TextL>
            <Image src={optimal_6} alt="optimal_6" name="Рисунок 5. Ограничения" />
            <TextL>
                Результатом выполнения является управление, решающее задачу и порождаемая им траектория системы
            </TextL>
            <Image src={optimal_7} alt="optimal_7" name="Рисунок 6. Решение задачи" />
            <TextL>
                Задачу оптимального управления можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}

// eslint-disable-next-line no-empty-pattern
function OptimalManagementTaskWithPiecewiseConstantControlTab({}: TabHeaderComponentProps) {
    return (
        <>
            <TextL className="margin-t-24">
                Перед решением задачи предлагается ознакомится с выжимками <Link to="/theory">теории</Link> для данного типа задачи.
                Для построения графика кубической функции необходимо заполнить необходимые параметры.
            </TextL>
            <Image src={CubicParamExample} alt="CubicParamExample" name="Рисунок 1. Пример введения параметров" />
            <TextL>
                После чего ниже появится график функции, который соответствует введенным параметрам.
            </TextL>
            <Image src={CubicResultExample} alt="CubicResultExample" name="Рисунок 2. Результат построения графика с введенными параметрами" />
            <TextL>
                Задачу построения графика кубической функции можно решить на странице <Link to="/resolveTask">РЕШИТЬ СВОЮ ЗАДАЧУ</Link>.
            </TextL>
        </>
    );
}